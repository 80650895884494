import Typography from "typography"
import theme from "typography-theme-kirkham"

theme.overrideThemeStyles = () => ({
  'a': {
    color: "black",
  },
})
const typography = new Typography(theme)
export const { scale, rhythm, options } = typography
export default typography