import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import Logo from "../components/logo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const GlobalStyle = createGlobalStyle`
body {
  background-color: white;
}
`

const Container = styled.div`
  margin-top: 20px;
  margin-left: 3rem auto;
  margin-right: 3rem auto;
  max-width: 650;
  padding: 0 1rem;
`

const Header = styled.header`
  margin-bottom: 1.5rem;
`

const HomeHeading = styled.h3`
  display: inline;
  font-weight: normal !important;
`

const NavigationList = styled.ul`
  list-style: none;
  float: right;
`
const LinkItem = styled.li`
  display: inline-block;
  margin-right: 1rem;
`

const LogoContainer = styled.span`
  padding-right: 5px;
`

const SiteTitle = styled.div`
`

const SiteSubtitle = styled.div`
font-size: 0.75em;
color: black;
opacity: 0.5;
`

export const transitionDuration = 0.5

const ListLink = props => (
  <LinkItem>
    <AniLink fade style={{ textDecoration: `none` }} to={props.to} duration={transitionDuration}>{props.children}</AniLink>
  </LinkItem>
)

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `)
  return <Container>
    <GlobalStyle />
    <Header>
        <HomeHeading>
          <AniLink fade to="/" duration={transitionDuration}>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </AniLink>
          <ListLink to="/">
            <SiteTitle>{data.site.siteMetadata.title}</SiteTitle>
            <SiteSubtitle>{data.site.siteMetadata.subtitle}</SiteSubtitle>
          </ListLink>
        </HomeHeading>
      <NavigationList>
        <ListLink to="/contact">Contact</ListLink>
        <ListLink to="/blog">Blog</ListLink>
      </NavigationList>
  </Header>
    {children}
  </Container>
}