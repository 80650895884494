import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { rhythm } from "../../typography"
import Layout from "../components/layout"
import { PostInfo } from "../markdown-remark-types"

const PostHeading = styled.h3`
  margin-bottom: ${rhythm(1 / 4)};
`

const PostDate = styled.span`
  color: #bbb;
  padding-left: 0.5rem;
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            id
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
            }
            excerpt
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <h2>Mah Blog</h2>
      {data.allMarkdownRemark.edges.map((post: PostInfo) => (
        <div key={post.node.id}>
          <PostHeading>
            <Link to={post.node.fields.slug}>
              {post.node.frontmatter.title}
            </Link>
            <PostDate>{post.node.frontmatter.date}</PostDate>
          </PostHeading>
          <p>{post.node.excerpt}</p>
        </div>
      ))}
    </Layout>
  )
}
